export const SET_COMPANY_LIST = 'SET - Company list'
export const ADD_TO_COMPANY_LIST = 'SET - Add new data to Company list'
export const SET_COMPANY_LIST_FETCHING = 'SET - Fetch/Unfetch company list'
export const SET_COMPANY_LIST_MAP = 'SET - Company Map'
export const SET_COMPANY_WATCHER = 'SET - Listen for company changes'
export const CURRENT_COMPANY = 'SET - Current company'
export const UPDATE_CURRENT_COMPANY = 'SET - Update current company'
export const SET_GICS_LIST = 'SET - Gics list'
export const SET_CASH_SOURCE_LIST = ' SET - Cash Source List'
export const TERMINAL = 'SET - Terminal Visibility'
export const SET_NEW_ISSUES_LIST = 'SET - New Issues filter'
export const SET_RAC_FILTER = 'SET - RAC filter'
export const SET_HISTORY_LIST = 'SET - User history list'
export const ADD_HISTORY_ENTRY = 'ADD - New history entry'
export const GET_UI_INFO = 'SET - UI Info'
export const SET_NEXT_REPORTING_LIST = 'SET - Next reporting list'
export const SET_MOST_VIEWED = 'SET - Most viewed'
export const SET_LAST_UPDATED_LIST = 'SET - Last updated companies'
export const SET_COMPANY_COUNT = 'SET - Company list count'
export const SET_WATCHING_FLAG = 'SET - Watching flag'
export const SET_SEARCH_FIELD = 'SET - Search parameter'
export const SET_SORTING = 'SET - Sorting variables'
export const SET_MEMO_STRUCTURE_LIST = 'SET - Memo structure list'
export const SET_COMPANY_LIST_ERROR = 'SET - Company list error message'
export const SET_COMPANY_LIST_PAGE = 'SET - Company list page'
export const SET_COMPANY_RECENT_DOCUMENTS = 'SET - Company Recently Added Documents'
export const SET_SEARCH_SUGGESTIONS = 'SET - Company Search Suggestions'

export default {
  [SET_MOST_VIEWED](state, payload) {
    state.mostViewedList = payload
  },
  [SET_NEXT_REPORTING_LIST](state, payload) {
    state.nextReportingList = payload
  },
  [SET_SORTING](state, payload) {
    state.companySorting = payload
  },
  [SET_SEARCH_FIELD](state, payload) {
    state.searchParam = payload
  },
  [SET_COMPANY_COUNT](state, payload) {
    state.companyListCount = payload
  },
  [SET_COMPANY_LIST_ERROR](state, payload) {
    state.companyListError = payload
  },
  [SET_LAST_UPDATED_LIST](state, payload) {
    state.lastReportedList = payload
  },
  [SET_NEW_ISSUES_LIST](state, payload) {
    state.newIssuesOnly = payload
  },
  [SET_RAC_FILTER](state, { type, value }) {
    state.racFilter = {
      ...state.racFilter,
      [type]: value,
    }
  },
  [TERMINAL](state, payload) {
    if (payload === state.terminalNavigationVisible) {
      return
    }

    state.terminalNavigationVisible = payload
  },
  [SET_HISTORY_LIST](state, payload) {
    state.historyList = payload
  },
  [ADD_HISTORY_ENTRY](state, payload) {
    state.historyList.unshift(payload)
  },
  [SET_COMPANY_LIST](state, payload) {
    state.companyList = payload
  },
  [ADD_TO_COMPANY_LIST](state, payload) {
    state.companyList = [...state.companyList, ...payload]
  },
  [SET_WATCHING_FLAG](state, payload) {
    const foundItemIndex = state.companyList?.findIndex((item) => item.id === payload)

    if (foundItemIndex > -1) {
      state.companyList = state.companyList.toSpliced(
        foundItemIndex,
        1,
        Object.assign({}, state.companyList[foundItemIndex], {
          favourited: !state.companyList[foundItemIndex].favourited,
        }),
      )
    }
  },
  [SET_COMPANY_LIST_FETCHING](state, payload) {
    state.companyListFetching = payload
  },
  // TO DO: This does not work - item.id does not exist in companyMap so it always returns []
  [SET_COMPANY_LIST_MAP](state, payload) {
    const companyMap = []

    payload.map((item) => {
      return (companyMap[item.id] = item)
    })
    state.companyMap = companyMap
  },
  [SET_COMPANY_WATCHER](state, payload = true) {
    state.companyWatcher = payload
  },
  [CURRENT_COMPANY](state, payload) {
    state.currentCompany = payload
  },
  [UPDATE_CURRENT_COMPANY](state, payload) {
    const companyCopy = { ...state.currentCompany }
    companyCopy[payload.key] = payload.value
    state.currentCompany = companyCopy
  },
  [SET_GICS_LIST](state, payload) {
    state.gicsList = payload
  },
  [SET_CASH_SOURCE_LIST](state, payload) {
    state.cashSourceList = payload
  },
  [GET_UI_INFO](state, payload) {
    state.companyUiInfo[payload.area] = payload.data
  },
  [SET_MEMO_STRUCTURE_LIST](state, payload) {
    state.memoStructureList = payload
  },
  [SET_COMPANY_LIST_PAGE](state, payload) {
    state.companyListPage = payload
  },
  [SET_COMPANY_RECENT_DOCUMENTS](state, payload) {
    state.recentDocuments = payload
  },
  [SET_SEARCH_SUGGESTIONS](state, payload) {
    state.searchSuggestions = payload
  },
}
